











































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import InputNumber from 'primevue/inputnumber';
import InputText from 'primevue/inputtext';
import Password from 'primevue/password';
import Dropdown from 'primevue/dropdown';
import Button from 'primevue/button';
import Card from 'primevue/card';
import { UserRoles } from '@/models/UserRoles';
import RootVue from '@/RootVue';
import { AgentClaimData } from '@/models/AgentClaimData';

@Component({
    components: {
        InputNumber,
        InputText,
        Password,
        Dropdown,
        Button,
        Card,
    }
})
export default class AgentClaimsCard extends Vue {
    roleOptions = [UserRoles.User, UserRoles.Admin];
    errorMessage = '';
    newEmail = '';

    get agents(): AgentClaimData[] {
        return (this.$root as RootVue).agentClaimsData;
    }

    get maxCredits(): number {
        return (this.$root as RootVue).tenant?.claimQuota ?? 0;
    }

    remainingCredits(agent: AgentClaimData) {
        return this.maxCredits - agent.claims;
    }

    async creditLead(agent: AgentClaimData) {
        let success = await (this.$root as RootVue).creditLead(agent.agentEmail);
        // TODO: Add success/fail message
    }

    async removeAgent(agent: AgentClaimData) {
        let success = await (this.$root as RootVue).deleteAgent(agent);
        // TODO: Add success/fail message
    }
}
