








import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import AgentClaimsCard from '@/components/dashboard/agents/AgentClaimsCard.vue';

@Component({
    components: {
        AgentClaimsCard,
    }
})
export default class SlackPanel extends Vue {
}
