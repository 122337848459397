
















import { Component, Vue } from "vue-property-decorator";
import SlackSetupPanel from "@/components/dashboard/SlackSetupPanel.vue";
import GlobalPanel from '@/components/dashboard/GlobalPanel.vue';
import SlackPanel from '@/components/dashboard/AgentsPanel.vue';
import HomePanel from '@/components/dashboard/HomePanel.vue';
import InputSwitch from 'primevue/inputswitch';

import TabMenu from 'primevue/tabmenu';
import Button from 'primevue/button';

import RootVue from "@/RootVue";
import { UserRoles } from "@/models/UserRoles";
import { ToastSeverity } from "primevue/api";

@Component({
	components: {
        SlackSetupPanel,
        GlobalPanel,
        SlackPanel,
        HomePanel,

        InputSwitch,
        TabMenu,
        Button,
	}
})
export default class Dashboard extends Vue {
    activeTab = 0;

    mounted() {
        (this.$root as RootVue).refreshData();
    }

    get root() {
        return (this.$root as RootVue);
    }

    get navItems() {
        let items = [
            { label: 'Home', icon: 'pi pi-fw pi-home' },
            { label: 'Setup', icon: 'pi pi-fw pi-slack' },
            { label: 'Agents', icon: 'pi pi-fw pi-user' }
        ];

        if (this.authorizedForGlobal) {
            items.push({ label: 'Global', icon: 'pi pi-fw pi-globe' });
        }

        return items;
    }

    get authorizedForGlobal() {
        let user = (this.$root as RootVue).user;
        return user?.role == UserRoles.GlobalAdmin;
    }

    logout() {
        window.location.href = 'logout';
    }

    async onToggle() {
        let res = await this.root.saveTenantData(true, true);
        if (res) {
            this.$toast.add({severity: ToastSeverity.SUCCESS, summary: 'Success!', detail: `Bot is ${this.root.tenant?.enabled ? 'ON' : 'OFF'}`, life: 3000});
        }
    }
}
