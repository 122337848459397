



























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import MultiSelect from 'primevue/multiselect';
import InputText from 'primevue/inputtext';
import Password from 'primevue/password';
import Button from 'primevue/button';
import Card from 'primevue/card';
import RootVue from '@/RootVue';

@Component({
    components: {
        MultiSelect,
        InputText,
        Password,
        Button,
        Card,
    }
})
export default class CRMSettingsCard extends Vue {
    apiNames: {[key: string]: string} = {
        'Sierra Interactive': 'sierraKey',
        'FollowUpBoss': 'followUpBossKey',
        'RealGeeks': 'realGeeksKey'
    };
    availableCRMs = ['Sierra Interactive', 'FollowUpBoss', 'RealGeeks'];
    selectedCRMs = ['Sierra Interactive'];

    get tenant() {
        return (this.$root as RootVue).tenant as any;
    }

    async save () {
        let success = (this.$root as RootVue).saveTenantData();
        // TODO: Add success/fail message
    }
}
