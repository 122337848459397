






















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import NotificationsSettingsCard from '@/components/dashboard/slack/NotificationsSettingsCard.vue';
import ChannelSettingsCard from '@/components/dashboard/slack/ChannelSettingsCard.vue';
import ClaimSettingsCard from '@/components/dashboard/slack/ClaimSettingsCard.vue';
import BanterCard from '@/components/dashboard/banter/BanterCard.vue';
import InputNumber from 'primevue/inputnumber';
import RootVue from '@/RootVue';

@Component({
    components: {
        NotificationsSettingsCard,
        ChannelSettingsCard,
        ClaimSettingsCard,
        BanterCard,
        InputNumber,
    }
})
export default class SlackSetupPanel extends Vue {
    get reminderDelay(): number { return (this.$root as RootVue).tenant?.unclaimedReminderDelay ?? 0; }
    set reminderDelay(value: number) {
        let tenant = (this.$root as RootVue).tenant;
        if (tenant) {
            tenant.unclaimedReminderDelay = value;
        }
    }

    get messages() {
        return (this.$root as RootVue).messages;
    }
}
