


































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import InputNumber from 'primevue/inputnumber';
import InputText from 'primevue/inputtext';
import Password from 'primevue/password';
import Dropdown from 'primevue/dropdown';
import Button from 'primevue/button';
import Card from 'primevue/card';
import { ChannelSettings } from '@/models/ChannelSettings';
import RootVue from '@/RootVue';
import axios from 'axios';

@Component({
    components: {
        InputNumber,
        InputText,
        Password,
        Dropdown,
        Button,
        Card,
    }
})
export default class ChannelSettingsCard extends Vue {
    errorMessage = '';

    get channels(): ChannelSettings[] { return (this.$root as RootVue).channels; }
    set channels(value: ChannelSettings[]) { (this.$root as RootVue).channels = value; }

    mounted() {
        this.ensureSort();
    }

    async addChannel() {
        this.errorMessage = '';

        let len = this.channels.length;
        let priceBracket = len == 0 ? 100000 : this.channels[len-1].priceBracket * 2;

        try {
            let res = await axios.post('api/channels', {
                name: 'newpool',
                priceBracket
            });
            this.channels.push(res.data);
        }
        catch (error: any) {
            let status = error.response?.status;
            this.errorMessage = `An error occurred [${status}]`;
        }
    }

    removeChannel(channel: ChannelSettings) {
        let $this = this;
        axios.delete('api/channels', {
            params: {
                id: channel.id
            }
        })
        .then(res => {
            let index = $this.channels.indexOf(channel);
            $this.channels.splice(index, 1);
        })
        .catch(error => {
            let status = error.response?.status;
            this.errorMessage = `An error occurred while deleting the channel [${status}]`;
        })
    }

    async save () {
        let success = (this.$root as RootVue).saveChannelData();
        // TODO: Add success/fail message
    }

    range(index: number) {
        let channel = this.channels[index];
        let previousBracket = index == 0 ? 0 : this.channels[index-1].priceBracket+1;

        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',

            // Round to whole numbers
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        });

        let isLast = index == this.channels.length - 1;
        return isLast ? `${formatter.format(previousBracket)}+` : `${formatter.format(previousBracket)} - ${formatter.format(channel.priceBracket)}`;
    }

    @Watch('channels')
    ensureSort() {
        let previous = -1;
        for (let channel of this.channels) {
            if (channel.priceBracket < previous) {
                this.channels.sort((a, b) => a.priceBracket - b.priceBracket);
                break;
            }
            previous = channel.priceBracket;
        }
    }
}
