import { render, staticRenderFns } from "./DashboardUsersCard.vue?vue&type=template&id=5db43f55&scoped=true&"
import script from "./DashboardUsersCard.vue?vue&type=script&lang=ts&"
export * from "./DashboardUsersCard.vue?vue&type=script&lang=ts&"
import style0 from "./DashboardUsersCard.vue?vue&type=style&index=0&id=5db43f55&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5db43f55",
  null
  
)

export default component.exports