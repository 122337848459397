import { UserRoles } from "./UserRoles";

export class User {
    email: string;
    password: string;
    tenantId: string;
    role: UserRoles;

    constructor({email, password, role, tenantId}: {email?: string, password?: string, role?: UserRoles, tenantId?: string}) {
        this.role = role ?? UserRoles.User;
        this.password = password ?? '******';
        this.tenantId = tenantId ?? '';
        this.email = email ?? '';
    }
}