








import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import TenantDashboardsCard from '@/components/dashboard/global/TenantDashboardsCard.vue';

@Component({
    components: {
        TenantDashboardsCard,
    }
})
export default class HomePanel extends Vue {
}
