















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import InputNumber from 'primevue/inputnumber';
import InputText from 'primevue/inputtext';
import Password from 'primevue/password';
import Dropdown from 'primevue/dropdown';
import Button from 'primevue/button';
import Card from 'primevue/card';
import RootVue from '@/RootVue';

@Component({
    components: {
        InputNumber,
        InputText,
        Password,
        Dropdown,
        Button,
        Card,
    }
})
export default class ClaimSettingsCard extends Vue {
    timeUnits = ['Seconds', 'Minutes', 'Hours', 'Days'];
    creditLeadInput = '';

    get humanReadableNotes() {
        let claimDelayUnit = this.adjustUnit(this.claimDelayUnit, this.claimDelay);
        let quotaDelayUnit = this.adjustUnit(this.quotaDelayUnit, this.quotaDelay);
        let leadsStr = this.adjustUnit('leads', this.quota);

        let claimDelay = `Agents must wait ${this.claimDelay} ${claimDelayUnit} between each claim.`;
        let claimQuota = `Agents may claim up to ${this.quota} ${leadsStr} every ${this.quotaDelay} ${quotaDelayUnit}`;

        let notes = [];
        if (this.claimDelay > 0) notes.push(claimDelay);
        if (this.quota > 0 && this.quotaDelay > 0) notes.push(claimQuota);
        return notes;
    }

    adjustUnit(unit: string, qty: number) {
        return (qty == 1) ? unit.substring(0, unit.length-1) : unit;
    }

    async save() {
        let success = await (this.$root as RootVue).saveTenantData();
        // TODO: Add success/fail message
    }

    get tenant() {
        return (this.$root as RootVue).tenant;
    }
    
    get claimDelay() { return this.tenant?.claimDelay ?? 0 }
    set claimDelay(value: number) { if (this.tenant) this.tenant.claimDelay = value }

    get quota() { return this.tenant?.claimQuota ?? 0 }
    set quota(value: number) { if (this.tenant) this.tenant.claimQuota = value }

    get quotaDelay() { return this.tenant?.quotaTimeframe ?? 0 }
    set quotaDelay(value: number) { if (this.tenant) this.tenant.quotaTimeframe = value }

    get claimDelayUnit() { return this.tenant?.delayUnit ?? 'Seconds' }
    set claimDelayUnit(value: string) { if (this.tenant) this.tenant.delayUnit = value }

    get quotaDelayUnit() { return this.tenant?.timeframeUnit ?? 'Hours' }
    set quotaDelayUnit(value: string) { if (this.tenant) this.tenant.timeframeUnit = value }

}
