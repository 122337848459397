










































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import InputNumber from 'primevue/inputnumber';
import InputText from 'primevue/inputtext';
import Password from 'primevue/password';
import Dropdown from 'primevue/dropdown';
import Button from 'primevue/button';
import Card from 'primevue/card';
import { ChannelSettings } from '@/models/ChannelSettings';
import { User } from '@/models/User';
import { UserRoles } from '@/models/UserRoles';
import RootVue from '@/RootVue';
import axios from 'axios';

@Component({
    components: {
        InputNumber,
        InputText,
        Password,
        Dropdown,
        Button,
        Card,
    }
})
export default class DashboardUsersCard extends Vue {
    roleOptions = [UserRoles.User, UserRoles.Admin];
    errorMessage = '';
    newEmail = '';

    get users(): User[] {
        return (this.$root as RootVue).usersInTenant;
    }

    isSelf(user: User) {
        let auth = (this.$root as RootVue).user;
        return auth?.email == user.email;
    }

    isAccountRole(user: User) {
        return this.roleOptions.includes(user.role);
    }

    async resetPassword(user: User) {
        try {
            const result = await axios.post('api/users/reset_password', { email: user.email });
            if (result.status == 200 && result.data?.success) {
                user.password = result.data.newPassword;
                this.errorMessage = '';
            }
            else {
                console.log(result.data);
                this.errorMessage = `Error: Unexpected server response`;
            }
        }
        catch (error: any) {
            console.log(error);
            let status = error.response?.status;
            this.errorMessage = `Error: [${status}]`;
        }
    }

    randomPassword() {
        return Math.random().toString(36).slice(2);
    }

    async addDashboardUser() {
        let newEmail = this.newEmail.trim();
        if (newEmail == '') {
            this.errorMessage = 'Email cannot be blank';
        }
        else if (!/^[a-zA-Z0-9@\._$ ]+$/.test(newEmail)) {
            this.errorMessage = 'Username format is not valid.';
        }
        else if (this.users.filter(x => x.email == newEmail).length > 0) {
            this.errorMessage = 'There is already an account with this username';
        }
        else {
            try {
                let user = new User({
                    email: newEmail,
                    password: this.randomPassword(),
                    role: UserRoles.Admin,
                    tenantId: (this.$root as RootVue).user?.tenantId
                })

                await axios.post('api/users', user);
                this.users.push(user);
                this.errorMessage = '';
            }
            catch (error: any) {
                console.log(error);
                let status = error.response?.status;
                this.errorMessage = `Error: [${status}]`;
            }
        }
    }

    removeUser(index: number) {
        let user = this.users[index];
        let $this = this;
        axios.delete('api/users', {
            params: {
                email: user.email
            }
        })
        .then(res => {
            let index = $this.users.indexOf(user);
            $this.users.splice(index, 1);
        })
        .catch(error => {
            let status = error.response?.status;
            this.errorMessage = `An error occurred while deleting the user [${status}]`;
        })
    }
}
