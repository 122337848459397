






















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import InputText from 'primevue/inputtext';
import Checkbox from 'primevue/checkbox';
import Password from 'primevue/password';
import TabMenu from 'primevue/tabmenu';
import Button from 'primevue/button';
import Card from 'primevue/card';

import leadFields from '@/data/leadFields';
import RootVue from '@/RootVue';

@Component({
    components: {
        InputText,
        Checkbox,
        Password,
        TabMenu,
        Button,
        Card,
    }
})
export default class NotificationsSettingsCard extends Vue {
    get activeFields() {
        return (this.$root as RootVue).tenant?.notificationFields ?? [];
    }
    set activeFields(value: string[]) {
        let tenant = (this.$root as RootVue).tenant;
        if (tenant) tenant.notificationFields = value;
    }

    get fields() {
        return leadFields;
    }

    sentenceCase(str: string) {
        let split = str.replace('searchPreference_', '').replace(/([A-Z])/g, ' $1');
        return split.charAt(0).toUpperCase() + split.substring(1);
    }

    async save () {
        let success = (this.$root as RootVue).saveTenantData();
        // TODO: Add success/fail message
    }
}
