














































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import DashboardUsersCard from '@/components/dashboard/home/DashboardUsersCard.vue';
import CRMSettingsCard from '@/components/dashboard/home/CRMSettingsCard.vue';

import Card from 'primevue/card';
import RootVue from '@/RootVue';
import axios from 'axios';

@Component({
    components: {
        DashboardUsersCard,
        CRMSettingsCard,
        Card,
    }
})
export default class HomePanel extends Vue {
    stats = {
        outstandingLeads: '...',
        claimedLeads: '...',
        activeRecords: '...'
    };

    async mounted() {
        try {
            this.stats = (await axios.get('api/dashboard/stats')).data;
        }
        catch (error: any) {
            console.log('Error fetching stats', error);
        }
    }

    get email() {
        let user = (this.$root as RootVue).user;
        return user?.email ?? 'null';
    }
}
