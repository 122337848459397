const leadFields = [
    'firstName',
    'lastName',
    'email',
    'phone',
    'source',
    'streetAddress',
    'city',
    'state',
    'zip',
    'shortSummary',
    'tags',
    'leadType',
    'searchPreference_minPrice',
    'searchPreference_maxPrice'
];

export default leadFields;