

















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import InputNumber from 'primevue/inputnumber';
import InputText from 'primevue/inputtext';
import Password from 'primevue/password';
import Dropdown from 'primevue/dropdown';
import Button from 'primevue/button';
import Card from 'primevue/card';
import { User } from '@/models/User';
import { Tenant } from '@/models/Tenant';
import axios from 'axios';
import RootVue from '@/RootVue';
import { UserRoles } from '@/models/UserRoles';

@Component({
    components: {
        InputNumber,
        InputText,
        Password,
        Dropdown,
        Button,
        Card,
    }
})
export default class TenantDashboardsCard extends Vue {
    defaultPassword = '';
    newTenantId = '';
    rootEmail = '';

    errorMessage = '';

    mounted() {
        this.defaultPassword = this.randomPassword();
    }

    get tenants(): Tenant[] {
        return (this.$root as RootVue).tenants;
    }

    get myTenant(): Tenant|null {
        return (this.$root as RootVue).tenant;
    }

    get myUser(): User|null {
        return (this.$root as RootVue).user;
    }

    showDelete(tenant: Tenant) {
        return (this.myUser?.email == 'root' || !this.isMyTenant(tenant));
    }

    isMyTenant(tenant: Tenant) {
        return this.myTenant?.id == tenant.id
    }

    randomPassword() {
        return Math.random().toString(36).slice(2);
    }

    async addTenant() {
        let newTenantId = this.newTenantId.trim();
        let rootEmail = this.rootEmail.trim();
        if (newTenantId == '') {
            this.errorMessage = 'Tenant Name cannot be blank';
        }
        else if (!/^[a-zA-Z0-9@\._$ ]+$/.test(rootEmail)) {
            this.errorMessage = 'Default User format is not valid';
        }
        else if (this.tenants.filter(x => x.id == newTenantId).length > 0) {
            this.errorMessage = 'There is already an account with this Tenant Name';
        }
        else {
            this.errorMessage = '';
            let tenant: Tenant = {
                id: newTenantId,

                enabled: false,

                sierraKey: '',
                realGeeksKey: '',
                followUpBossKey: '',
                
                claimDelay: 0,
                claimQuota: 0,
                quotaTimeframe: 0,
                
                delayUnit: 'Seconds',
                timeframeUnit: 'Days',

                unclaimedReminderDelay: 10,

                notificationFields: [
                    'firstName',
                    'city',
                    'source',
                    'searchPreference_minPrice'
                ]
            };

            try {
                let res = await axios.post('api/tenants', tenant);
                this.tenants.push(res.data);
                
                let user = new User({
                    email: this.rootEmail,
                    password: this.defaultPassword,
                    role: UserRoles.AccountOwner,
                    tenantId: this.newTenantId
                })
                await axios.post('api/users', user);

                let defaultChannels = [
                    { name: 'rentalpool', priceBracket: 100000 },
                    { name: 'freeleadspool', priceBracket: 599999 },
                    { name: 'leadpool', priceBracket: 1999999 },
                    { name: 'luxurypool', priceBracket: 4000000 }
                ]
                for (let channel of defaultChannels) {
                    await axios.post(`api/channels?tenantId=${newTenantId}`, channel);
                }

                this.defaultPassword = this.randomPassword();
                this.newTenantId = '';
                this.rootEmail = '';
            }
            catch (error: any) {
                let status = error.response?.status;
                if (status == 409) {
                    this.errorMessage = 'There is already an account with this Tenant Name or Email Address.';
                }
                else {
                    this.errorMessage = `An error occurred [${status}]`;
                }
            }
        }
    }

    removeTenant(index: number) {
        let tenant = this.tenants[index];
        let $this = this;
        axios.delete('api/tenants', {
            params: {
                id: tenant.id
            }
        })
        .then(res => {
            let index = $this.tenants.indexOf(tenant);
            $this.tenants.splice(index, 1);
        })
        .catch(error => {
            let status = error.response?.status;
            this.errorMessage = `An error occurred while deleting the tenant [${status}]`;
        })
    }
}
