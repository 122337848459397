































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Card from 'primevue/card';
import RootVue from '@/RootVue';
import { MessageTypes } from '@/models/MessageTypes';
import { Message } from '@/models/Message';
import axios from 'axios';
import { ToastSeverity } from 'primevue/api';

@Component({
    components: {
        InputText,
        Button,
        Card,
    }
})
export default class BanterCard extends Vue {
    @Prop({required: true})
    title!: string;
    
    @Prop({required: true})
    subtitle!: string;

    @Prop({required: true})
    messageType!: MessageTypes;

    @Prop({required: true})
    messages!: Message[];

    @Prop({default: false})
    saveTenant!: boolean;

    @Prop({required: true})
    defaultMessage!: string;

    errorMessage = '';

    get showDefault() {
        let filter = this.messages.filter(x => x.message?.trim().length > 0);
        return filter.length == 0;
    }

    async addMessage() {
        this.errorMessage = '';

        try {
            let res = await axios.post('api/messages', {messageType: this.messageType});
            this.messages.push(res.data);
        }
        catch (error: any) {
            let status = error.response?.status;
            this.errorMessage = `An error occurred [${status}]`;
        }
    }

    removeMessage(message: Message) {
        let $this = this;
        axios.delete('api/messages', {
            params: {
                id: message.id
            }
        })
        .then(res => {
            let index = $this.messages.indexOf(message);
            $this.messages.splice(index, 1);
        })
        .catch(error => {
            let status = error.response?.status;
            this.errorMessage = `An error occurred while deleting the message [${status}]`;
        })
    }

    get root(): RootVue {
        return this.$root as RootVue;
    }

    async save () {
        let success = await this.root.saveMessageData();
        // TODO: Add success/fail message

        if (this.saveTenant) {
            let success = await this.root.saveTenantData(true, true);
        }
    }
}
